import { VuexModule, Module, Action } from 'vuex-class-modules';
import Axios from 'axios';
import store from '@/store';
import { Paginate } from '@/interfaces/paginate';

@Module({ generateMutationSetters: true })
export class BuilderModule extends VuexModule {
    projects: any[] = [];
    builderSearches: any[] = [];
    projectSearches: any[] = [];

    @Action
    async index(search?: string) {
        if (this.builderSearches.length < 1) {
            const { data } = await Axios.get<
                {
                    builderId: number;
                    name: string;
                    projectId: number;
                    tradename: string;
                    _id: string;
                }[]
            >('project/obtener-todos-los-proyectos', {
                params: { search, ignoreLoading: true }
            });
            this.builderSearches = data.filter(
                (project, index, self) =>
                    index ===
                    self.findIndex(t => t.tradename === project.tradename)
            );
        }
        return this.builderSearches;
    }

    @Action
    async searchProjects(search?: string) {
        if (this.projectSearches.length < 1) {
            const { data } = await Axios.get(
                'project/obtener-todos-los-proyectos',
                {
                    params: { search, ignoreLoading: true }
                }
            );
            this.projectSearches = data;
        }
        return this.projectSearches;
    }

    @Action
    async getProjects(filter: {
        projectId?: number;
        builderId?: number;
        all: boolean;
    }) {
        const { data } = await Axios.get<Paginate<any>>(
            'project/get-data-from-filter-v2',
            { params: filter }
        );
        this.projects = data.data;
    }

    @Action
    async setProject(data: any[]) {
        this.projects = data;
    }
}
export const builderModule = new BuilderModule({
    store,
    name: 'builder'
});
